import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')
];

export const server_loads = [];

export const dictionary = {
		"/(main)": [~3,[2]],
		"/(main)/about": [4,[2]],
		"/(main)/account": [5,[2]],
		"/(main)/account/details": [7,[2]],
		"/(main)/account/orders": [8,[2]],
		"/(main)/account/points": [~9,[2]],
		"/(main)/account/[oid]": [6,[2]],
		"/(main)/cart": [10,[2]],
		"/(main)/checkout": [11,[2]],
		"/(main)/checkout/confirming": [~12,[2]],
		"/(main)/checkout/step-one": [13,[2]],
		"/(main)/checkout/step-three": [~14,[2]],
		"/(main)/checkout/step-two": [15,[2]],
		"/(main)/hosting": [16,[2]],
		"/(main)/login": [17,[2]],
		"/(main)/notify": [18,[2]],
		"/(main)/plans": [19,[2]],
		"/(main)/privacy": [20,[2]],
		"/(main)/prizes": [21,[2]],
		"/(main)/prizes/offline": [23,[2]],
		"/(main)/prizes/[pid]": [~22,[2]],
		"/(main)/register": [24,[2]],
		"/(main)/rewards": [25,[2]],
		"/(main)/search": [26,[2]],
		"/(main)/success": [27,[2]],
		"/(main)/success/[oid]": [28,[2]],
		"/(main)/terms-use-policy": [30,[2]],
		"/(main)/terms": [29,[2]],
		"/(main)/use-policy": [31,[2]],
		"/(main)/winners": [~32,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';