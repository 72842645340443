import * as Sentry from '@sentry/sveltekit';

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
	dsn: 'https://3b487042bd15475c97e4cc36c0e75ff9@o1032296.ingest.us.sentry.io/6078001',
	tracesSampleRate: 1,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1,
	integrations: [Sentry.replayIntegration()]
});

const worker = new Worker("service-worker.js");

export const handle = async ({ event, resolve }) => {
	return await resolve(event);
};

export const handleError = async ({ error, event }) => {
	const errorId = crypto.randomUUID();

	console.log('Error ID:', errorId);
	console.error('Error processing:', error);
	Sentry.captureException(error, {
		contexts: { sveltekit: { event, errorId } }
	});

	return {
		message: "An unexpected error occurred on client. We're working on it! " + errorId
	};
};
